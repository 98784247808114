<template>
  <div class="layout__website">
    <navbar class="layout__website__navbar" />
    <div class="layout__website__content">
      <vue-page-transition name="overlay-up">
        <router-view />
      </vue-page-transition>
    </div>
    <footer-layout class="layout__website__footer" />
  </div>
</template>

<script>
import Navbar from "./Navbar";
import FooterLayout from "./Footer";

export default {
  components: {
    Navbar,
    FooterLayout
  }
};
</script>
