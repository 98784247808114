<template>
  <section class="text-left page-section">
    <!--NavBar-->

    <!-- <div class="mt-3">
        <b-pagination-nav 
        v-model="currentPage" 
        pills 
        :limit="10" 
        :link-gen="linkGen" 
        :number-of-pages="number_of_pages"
        align="center" 
        base-url="#"
        use-router>
        </b-pagination-nav>
    </div>-->

    <div v-if="!admin" class="container">
      <div class="form-inline">
        <div class="form-group mb-2">
          <label for="staticEmail2" class="sr-only">Email</label>
          <input
            type="text"
            readonly
            class="form-control-plaintext"
            id="staticEmail2"
            value="lar.ifce@admin.com"
          />
        </div>
        <div class="form-group mx-sm-3 mb-2">
          <label for="inputPassword2" class="sr-only">Password</label>
          <input
            type="password"
            class="form-control"
            placeholder="Senha"
            v-model="password"
          />
        </div>
        <button
          type="submit"
          class="btn btn-success mb-2"
          @click="validaLogin(password)"
        >
          Confirme sua identidade
        </button>
      </div>
    </div>
    <div v-if="admin">
      <div class="container">
        <h2>Estabelecimentos</h2>
      </div>
      <b-container>
        <b-row>
          <b-col md="6" sm="12" class="mb-3">
            <input
              type="text"
              v-model="nome"
              class="form-control"
              placeholder="Nome"
            />
          </b-col>
          <b-col md="6" sm="12" class="mb-3">
            <v-select
              id="input-2"
              v-model="tipoEstabelecimento"
              :options="tiposEstabelecimento"
              placeholder="Categoria do Estabelecimento"
              label="nome"
            ></v-select>
          </b-col>
          <b-col md="6" sm="12" class="mb-3">
            <v-select
              id="input-2"
              v-model="estado"
              :options="estados"
              placeholder="Estado"
              label="name"
            ></v-select>
          </b-col>
          <b-col md="6" sm="12" class="mb-3">
            <v-select
              id="input-2"
              v-model="cidade"
              :options="cidades"
              placeholder="Cidade"
              label="nome"
              :disabled="!cidades.length > 0"
            ></v-select>
          </b-col>
        </b-row>
      </b-container>

      <!--
          <b-form-checkbox-group
            v-model="selection.redessociais"
            id="checkboxes-4"
            :options="redessociais"
            text-field="label"
            value-field="value"
            stacked
           ></b-form-checkbox-group>
      -->
      <div>
        <b-form-checkbox-group
          id="checkbox-group-1"
          v-model="selection.redesSociais"
          :options="redesSociais"
          name="flavour-1"
        ></b-form-checkbox-group>
      </div>

      <b-container>
        <b-row>
          <b-col md="6" sm="12" class="mb-3">
            <b-button
              class="botao"
              @click="loading ? () => {} : changedFilter()"
            >
              {{ $t("geral.buttons.pesquisar") }}</b-button
            >
            <b-button class="limpa" @click="loading ? () => {} : reload()">{{
              $t("geral.buttons.limpar")
            }}</b-button>
            <!-- <b-button
              class="estab"
              @click="loading ? () => {} : reload()"
            >Estabelecimentos Duplicados</b-button> 
           -->
          </b-col>
        </b-row>
      </b-container>

      <div v-if="!loading" class="container">
        <div>
          <p class="mt-4 text-right">
            <b>Total:</b>
            {{ totalResultado }} Estabelecimento{{
              totalResultado > 1 ? "s" : ""
            }}
          </p>
          <fnl-list-item v-for="item in estabelecimentos" :key="item.id">
            <template v-slot:imagem>
              <img
                src="@/assets/img/store-icon.svg"
                alt="Ícone de lojinha"
                style="border: 1px solid rgba(0,0,0,0.15);"
              />
            </template>
            <template v-slot:info_primary>
              <p class="my__list__item__main__info__primary__title">
                {{ item.nome }}
              </p>
              <p class="my__list__item__main__info__primary__subtitle">
                {{ item.cidade }} - {{ item.estado.uf }}
              </p>
            </template>
            <template v-slot:more_actions>
              <b-button variant="outline-secondary" @click="editar(item.id)"
                >Editar</b-button
              >
              <b-button
                variant="outline-secondary"
                class="ml-2"
                @click="showModal(item)"
                >Excluir</b-button
              >
            </template>
            <template v-slot:overplus>
              <h5>Estabelecimento</h5>
              <hr />
              <p>{{ item.cnpj }}</p>
              <h6>Categoria do estabelecimento</h6>
              <div
                v-for="tipo in item.tiposEstabelecimento"
                :key="tipo.nome"
                pill
                class="mr-2 tag-item"
                variant="secondary"
              >
                {{ tipo.nome }}
              </div>
              <h6 class="mt-4">Funcionamento</h6>
              <b-badge
                v-for="dia in item.diasFuncionamento"
                :key="dia"
                pill
                class="mr-2"
                variant="secondary"
                >{{ dia }}</b-badge
              >
              <p class="mt-2">{{ item.horarioFuncionamento }}</p>

              <p class="tag-item" v-if="item.isDelivery">
                Delivery
                <b-badge
                  v-if="item.valordelivery"
                  pill
                  class="mr-2"
                  variant="secondary"
                  >R$ {{ item.valordelivery }}</b-badge
                >
              </p>
              <p class="tag-item" v-if="item.isPermiteRetirada">Retirada</p>

              <p v-if="item.tempoEspera">
                <b>tempo de espera</b>
                : {{ item.tempoEspera }} Minutos
              </p>

              <p v-if="item.descricao">{{ item.descricao }}</p>

              <p v-if="item.aceitaRecomendacoes">
                <b>Aceita recomendações</b>
              </p>

              <h5 class="mt-5">Endereço</h5>
              <hr />
              <p>
                {{
                  `${item.enderecoObj.rua},${item.enderecoObj.numero}, ${item.enderecoObj.referencia}, ${item.enderecoObj.bairro}, ${item.enderecoObj.cep}`
                }}
              </p>

              <h5 class="mt-5">Contato</h5>
              <hr />
              <p v-for="(tel, index) in item.telefones" :key="`index${index}`">
                <unicon
                  :name="tel.whatsapp ? 'whatsapp' : 'phone'"
                  :fill="tel.whatsapp ? 'green' : 'gray'"
                />
                {{ tel.numero ? tel.numero : "( Vazio )" }}
              </p>
              <p v-show="item.instagram">
                <unicon name="instagram" fill="gray" />
                {{ item.instagram }}
              </p>
              <p v-show="item.facebook">
                <unicon name="facebook" fill="gray" />
                {{ item.facebook }}
              </p>
              <p v-show="item.site">
                <unicon name="globe" fill="gray" />
                {{ item.site }}
              </p>
            </template>
          </fnl-list-item>

          <div class="mt-3">
            <b-pagination-nav
              v-model="currentPage"
              pills
              :limit="10"
              :link-gen="linkGen"
              :number-of-pages="number_of_pages"
              align="center"
              base-url="#"
              use-router
            ></b-pagination-nav>
          </div>
        </div>
      </div>
      <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center mt-4"
      >
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div>
    </div>
    <div>
      <b-modal
        id="mdelete"
        ref="mdelete"
        hide-footer
        title="Deletar Estabelecimento"
      >
        <p class="my-4">
          Deseja Deletar o Estabelecimento {{ objToDelete.nome }}?
        </p>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="hideModal()">
            Cancelar
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="deleteEstabelecimento()"
          >
            Deletar
          </button>
        </div>
      </b-modal>

      <!-- funcionalidade nova
     <div class="overflow-auto">
        <b-pagination-nav pills 
        :limit="10" 
        :link-gen="linkGen" 
        :number-of-pages="100" 
        base-url="#">

        </b-pagination-nav>
      </div>
      -->
    </div>
  </section>
</template>

<script>
import Service from "../services/ApiServices";
import FnlListItem from "@/components/FnlListItem";

export default {
  components: {
    FnlListItem
  },
  data() {
    return {
      number_of_pages: 1,
      currentPage: 1,
      tiposEstabelecimento: [],
      estabelecimentos: [],
      totalResultado: 0,
      page: 0,
      last: false,
      password: "",
      admin: false,
      nome: "",
      cidade: "",
      tipoEstabelecimento: "",
      replaceContent: false,
      loading: true,
      objToDelete: {},
      estados: [],
      estado: "",
      cidades: [],
      loadingScroll: null,
      selection: {
        redesSociais: []
      },
      redesSociais: [
        { text: "WhatsApp", value: "whatsapp" },
        { text: "Instagram", value: "instagram" },
        { text: "Facebook", value: "facebook" },
        { text: "Site", value: "site" }
      ],
      show: true
    };
  },
  created() {
    if (localStorage.adminnastro && localStorage.adminnastro == "true")
      this.admin = localStorage.adminnastro;
    else this.$router.push({ path: "/areaparceiro" });

    // this.estado = {
    //   name: localStorage.devFilterEstado ? localStorage.devFilterEstado : ""
    // };
    // this.cidade = {
    //   nome: localStorage.devFilterCidade ? localStorage.devFilterCidade : ""
    // };
    // this.tipoEstabelecimento = {
    //   nome: localStorage.devFilterTipoEstabelecimento
    //     ? localStorage.devFilterTipoEstabelecimento
    //     : ""
    // };
    this.nome = localStorage.devFilterNome ? localStorage.devFilterNome : "";

    this.getEstabelecimentos();
    this.getEstados();
    this.getTiposEstabelecimentos();
  },

  watch: {
    currentPage() {
      this.loadMore(this.currentPage);
    },
    // tipoEstabelecimento() {
    //   if (this.tipoEstabelecimento == null) this.tipoEstabelecimento = "";
    //   this.changedFilter();
    // },
    estado() {
      this.cidades = [];
      this.cidade = "";
      if (!this.estado) {
        this.estado = "";
      } else if (this.estado !== "") {
        this.getCidades();
      }
    }
    // cidade() {
    //   if (this.cidade == null) {
    //     this.cidade = "";
    //   } else if (this.estado !== "") {
    //     this.changedFilter();
    //   }
    // }
  },

  methods: {
    linkGen() {
      return "?";
    },
    redesSociaisSelected(redeSocial) {
      return (
        this.selection.redesSociais.filter(e => e === redeSocial)[0] !=
        undefined
      );
    },
    loadMore(page_number) {
      this.page = page_number - 1;
      const request = new Service();
      this.loading = true;
      request
        .findByParams(
          {
            nome: this.nome,
            estado: this.estado?.name || "",
            cidade: this.cidade?.nome || "",
            tipoEstabelecimento: this.tipoEstabelecimento || "",
            page: this.page,
            sort: "cidadeObj.nome",
            facebook: this.redesSociaisSelected("facebook"),
            instagram: this.redesSociaisSelected("instagram"),
            site: this.redesSociaisSelected("site"),
            whatsapp: this.redesSociaisSelected("whatsapp")
          },
          "empresas/findByParams"
        )
        .then(res => {
          this.last = res.last;
          this.estabelecimentos = res.content;
          this.loading = false;
          this.totalResultado = res.totalElements;
          this.number_of_pages = res.totalPages;
        });
    },
    getEstabelecimentos() {
      this.loading = true;
      this.page = 0;
      const request = new Service();

      localStorage.setItem(
        "devFilterEstado",
        this.estado.name ? this.estado.name : ""
      );
      localStorage.setItem(
        "devFilterCidade",
        this.cidade.nome ? this.cidade.nome : ""
      );
      localStorage.setItem(
        "devFilterTipoEstabelecimento",
        this.tipoEstabelecimento.nome ? this.tipoEstabelecimento.nome : ""
      );
      localStorage.setItem("devFilterNome", this.nome ? this.nome : "");

      request
        .findByParams(
          {
            nome: this.nome,
            estado: this.estado.name ? this.estado.name : "",
            cidade: this.cidade.nome ? this.cidade.nome : "",
            tipoEstabelecimento: this.tipoEstabelecimento.nome
              ? this.tipoEstabelecimento.nome
              : "",
            page: this.page,
            sort: "cidadeObj.nome"
          },
          "empresas/findByParams"
        )
        .then(res => {
          this.last = res.last;
          this.estabelecimentos = res.content;
          this.loading = false;
          this.totalResultado = res.totalElements;
          this.number_of_pages = res.totalPages;
        });
    },
    changedFilter() {
      this.estabelecimentos = [];
      this.replaceContent = true;
      this.page = 0;
      this.last = false;
      if (this.currentPage == 1) this.loadMore(this.currentPage);
      this.currentPage = 1;
    },
    reload() {
      this.estabelecimentos = [];
      this.totalElements = 0;
      this.page = 0;
      this.last = false;
      this.nome = "";
      this.tipoEstabelecimento = "";
      this.cidade = "";
      this.estado = "";
      this.selection.redesSociais = [];
      if (this.currentPage == 1) this.loadMore(this.currentPage);
      this.currentPage = 1;
    },

    showModal(obj) {
      this.objToDelete = obj;
      this.$refs["mdelete"].show();
    },

    hideModal() {
      this.objToDelete = {};
      this.$refs["mdelete"].hide();
    },

    deleteEstabelecimento() {
      if (this.objToDelete.id) {
        const request = new Service();
        request.delete(this.objToDelete.id, "empresas/delete").then(() => {
          this.hideModal();
          this.changedFilter();
          this.getEstabelecimentos();
        });
      }
    },

    getTipos(tipos = []) {
      let resposta = "";
      for (const t of tipos) {
        resposta = resposta + " " + t.nome + " | ";
      }
      return resposta;
    },

    validaLogin(senha = "") {
      if (senha === "laradminifce2020") {
        this.admin = true;
        localStorage.setItem("adminnastro", this.admin);
      } else this.admin = false;
    },

    editar(id) {
      this.$router.push({
        name: "Edit",
        params: {
          id: id
        }
      });
    },

    async getTiposEstabelecimentos() {
      const request = new Service();
      const resp = await request.getAll(
        { sort: "nome", size: 50 },
        "tiposEstabelecimento"
      );
      if (resp) {
        this.tiposEstabelecimento = resp.content.map(e => e.nome);
      }
    },

    async getEstados() {
      const request = new Service();
      const resp = await request.getAll({ sort: "nome", size: 27 }, "estados");
      if (resp) {
        this.estados = resp.content;
      }
    },

    async getCidades() {
      const request = new Service();
      let resp = undefined;
      if (this.estado && this.estado != "")
        resp = await request.getAll(
          { sort: "nome", nomeEstado: this.estado.name },
          "cidades/findByEstado"
        );
      if (resp) {
        this.cidades = resp.content;
      }
    }
  }
};
</script>

<style lang="scss">
.page-item.active .page-link {
  z-index: 4 !important;
  color: #fff !important;
  background-color: #46b766 !important;
  border-color: #46b766 !important;
}

.page-item .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #46b766;
  background-color: white;
  border: 1px solid #dee2e6;
}

.page-item a:hover {
  color: #46b766;
}

.page-section {
  padding: 3rem 0;
}

.mb-3 .botao {
  margin: 2px;
  width: auto;
  padding: 4 6px;
  color: #fff;
  background-color: #5fcd72;
  border-color: #5fcd72;
  border: none;

  //box-shadow: 1.5px 1.5px dimgray;
}

.mb-3 .botao:hover {
  color: #fff;
  background-color: #2ecc71;
  border-color: #2ecc71;
}

.mb-3 .limpa {
  margin: 2px;
  width: auto;
  padding: 4 6px;
  border: none;
  color: #fff;
  //box-shadow: 1.5px 1.5px dimgray;
}

.mb-3 .estab {
  margin: 2px;
  width: auto;
  padding: 2 6px;
  border: none;
  color: #fff;
  position: absolute;
  top: 0;
  right: -97%;
  //box-shadow: 1.5px 1.5px dimgray;
}

p a {
  font-size: 0.9rem;
}
.page-link:hover {
  z-index: 2;
  color: #5fcd72;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
div#checkbox-group-1 {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
</style>
