import Layout from "@/layout/Layout.vue";
import LayoutParceiro from "@/views/AreaParceiro.vue";
import Admin from "@/views/Developers";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue")
  },
  {
    path: "/",
    component: Layout,
    redirect: "/servicos",
    children: [
      {
        path: "servicos",
        name: "Servicos",
        component: () => import("../views/Servicos.vue")
      },

      {
        path: "form",
        name: "Form",
        component: () => import("../views/Form.vue")
      },
      // {
      //   path: "developers",
      //   name: "Developers",
      //   component: () => import("../views/Developers.vue")
      // },
      {
        path: "edit",
        name: "Edit",
        component: () => import("../views/Edit.vue")
      },
      {
        path: "sobre",
        name: "Sobre",
        component: () => import("../views/About.vue")
      },
      {
        path: "noticias",
        name: "Noticias",
        component: () => import("../views/Noticias.vue")
      },
      {
        path: "dicas",
        name: "Dicas",
        component: () => import("../views/DicasCovid.vue")
      },
      {
        path: "termos",
        name: "Termos",
        component: () => import("../views/Termos.vue")
      },
      {
        path: "equipe",
        name: "equipe",
        component: () => import("../views/Equipe.vue")
      },
      {
        path: "/areaparceiro",
        component: LayoutParceiro,
        redirect: "areaparceiro/dashboard",
        children: [
          {
            path: "dashboard",
            name: "Dashboard",
            component: () => import("../views/dashboard/Graficos.vue")
          },
          {
            path: "mapa",
            name: "Mapa",
            component: () => import("../views/Mapa.vue")
          },
          {
            path: "relatorio",
            name: "Relatorio",
            component: () => import("../views/Relatorio.vue")
          },

          {
            path: "outros",
            name: "Outros",
            component: () => import("../views/dashboard/Graficos.vue")
          },
          {
            path: "/developers",
            component: Admin,
            redirect: "developers/Estabelecimentos",
            children: [
              {
                path: "estabelecimentos",
                name: "Estabelecimentos",
                component: () => import("../views/Estabelecimentos.vue")
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: () => import("../views/RedirectPage.vue")
  },
  {
    path: "*",
    redirect: "/"
  }
];

export default routes;
