<template>
  <div :class="` ${img_partner}`">
    <!-- 
  <div class="overflow-auto">
    <b-pagination-nav
    pills 
    :limit="10" 
    :link-gen="linkGen" 
    :number-of-pages="100" 
    base-url="#">
    </b-pagination-nav>
  </div>
    -->

    <div v-if="!partner" class="container pt-5">
      <h2 class="pb-5">{{ $t("area_parceiro.title") }}</h2>

      <div class="form-inline" @keydown.enter.prevent.self>
        <!-- formulario 1 -->

        <b-container>
          <b-row>
            <p>
              <b-col>
                <div class="form-group mx-sm-3 mb-2">
                  <label class="mr-2">{{ $t("geral.labels.usuario") }}:</label>
                  <input
                    id="user"
                    type="text"
                    class="form-control"
                    placeholder="meu_usuario"
                    v-model="usuario"
                  />
                </div>
              </b-col>
            </p>
          </b-row>
        </b-container>

        <!-- formulario 2 -->

        <b-container>
          <b-row>
            <p>
              <b-col>
                <div class="form-group mx-sm-3 mb-2">
                  <label class="mr-2">{{ $t("geral.labels.senha") }}:</label>
                  <input
                    id="password"
                    type="password"
                    class="form-control"
                    placeholder="*****"
                    v-model="password"
                    @keydown.enter="validaLogin(usuario, password)"
                  />
                </div>
              </b-col>
            </p>
          </b-row>
        </b-container>

        <!-- botão -->
        <div class="form-group mx-sm-3 mb-2">
          <b-container>
            <b-row>
              <b-col>
                <b-button
                  type="button"
                  class="btn btn-success mb-2"
                  @click="validaLogin(usuario, password)"
                  >{{ $t("geral.buttons.entrar") }}</b-button
                >
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>

      <span class="text-danger" v-if="loginInvalido">
        {{ $t("geral.mensagens.usuario-senha-invalido") }}
      </span>
    </div>
    <b-container class="mt-4" style="height: 100%; width: 100%;" v-if="partner">
      <div class="d-flex">
        <div class="p-2">
          <h4>{{ $t("geral.labels.bem-vindo") }}{{ titlePartner }}</h4>
        </div>
        <div class="ml-auto p-2">
          <b-button
            class="px-3"
            pill
            variant="outline-secondary"
            @click="logout"
          >
            {{ $t("geral.buttons.sair") }}
            <b-icon class="ml-2" icon="box-arrow-right"></b-icon>
          </b-button>
        </div>
      </div>
      <b-row>
        <b-col>
          <nav
            class="menu__parceiros d-flex justify-content-center align-items-center mb-3"
          >
            <router-link
              class="menu__parceiros__item"
              :class="{ active: $route.name === 'Dashboard' }"
              :to="{ name: 'Dashboard' }"
            >
              <b-icon icon="graph-up" scale="2"></b-icon>
              <p>{{ $t("geral.labels.dashboard") }}</p>
            </router-link>
            <router-link
              class="menu__parceiros__item"
              :class="{ active: $route.name === 'Mapa' }"
              :to="{ name: 'Mapa' }"
            >
              <b-icon icon="map" scale="2"></b-icon>
              <p>{{ $t("geral.labels.mapa") }}</p>
            </router-link>
            <router-link
              class="menu__parceiros__item"
              :class="{ active: $route.name === 'Relatorio' }"
              :to="{ name: 'Relatorio' }"
            >
              <b-icon icon="file-text" scale="2"></b-icon>
              <p>{{ $t("geral.labels.relatorio") }}</p>
            </router-link>

            <router-link
              v-if="adm"
              class="menu__parceiros__item"
              to="/developers/Estabelecimentos"
              :class="{ active: $route.name === 'developers' }"
            >
              <b-icon icon="gear-fill" scale="2"></b-icon>
              <p>{{ $t("geral.labels.developer") }}</p>
            </router-link>
            <!-- <router-link class="menu__parceiros__item" :to="{ name: 'Outros' }">
              <b-icon icon="map" scale="2"></b-icon>
              <p>Outro</p>
            </router-link>-->
          </nav>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <router-view></router-view>
        </b-col>
      </b-row>
    </b-container>
    <!-- <div class="container">
      <h2 class="pb-5">{{ titlePage }}</h2>
      <div class="card-deck mb-3 text-center">
        <div
          class="card mb-4 box-shadow"
          v-for="(card, index) in cards"
          :key="index"
        >
          <div class="card-header">
            <h4 class="my-0 font-weight-normal">{{ card.name }}</h4>
          </div>
          <div class="card-body">
            <b-icon
              :class="'card-icon ' + card.mt"
              :icon="card.img"
              scale="5"
            ></b-icon>
            <router-link
              v-if="!card.isDisabled"
              type="button"
              class="btn btn-lg btn-block btn-success"
              :to="card.link"
            >
              Acessar
            </router-link>
            <button
              v-else
              type="button"
              class="btn btn-lg btn-block btn-outline-success"
              :to="card.link"
              disabled
            >
              Em Breve
            </button>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      adm: false,
      parceiros: [
        "5e8099266bac487f3cf0ef5c", // Bahia
        "5e8099266bac487f3cf0ef56", // Ceará
        "5e8099266bac487f3cf0ef58", // Paraíba
        "5e80cfa915b7f33f89d35036", // Juazeiro do Norte
        "5e80cfa915b7f33f89d3471f", // Aracati
        "5e80cfa915b7f33f89d347e8", // Barbalha
        "5e80cfa915b7f33f89d35ae2", //Uberaba
        "5e80cfa915b7f33f89d3500a"  //Jericoacoara
      ],
      titlePage: "Área do Parceiro",
      titlePartner: "",
      password: "",
      usuario: "",
      loginInvalido: false,
      partner: false,
      partneraccess: "",
      img_partner: "",
      estadoParceiro: undefined
    };
  },
  created() {
    this.verify();
  },
  methods: {
    verify() {
      this.adm = localStorage.adminnastro;

      if (localStorage.partneraccess == true)
        localStorage.removeItem("partneraccess");

      if (localStorage.partneraccess || localStorage.adminnastro) {
        this.partneraccess = localStorage.partneraccess;
        this.partner = true;
        this.getPartner();
      }
    },
    getPartner() {
      if (localStorage.adminnastro && localStorage.adminnastro == "true") {
        this.titlePartner = ", Administrador #FiqueNoLar";
      } else if (
        localStorage.adminnastro &&
        localStorage.adminnastro == "partner"
      ) {
        this.titlePartner = ", Parceiro #FiqueNoLar";
      } else if (this.partneraccess === this.parceiros[0]) {
        // this.img_partner = "parceria-ba";
        this.titlePartner = ", Governo do Estado da Bahia";
      } else if (this.partneraccess === this.parceiros[1]) {
        // this.img_partner = "parceria-ce";
        this.titlePartner = ", Governo do Estado do Ceará";
      } else if (this.partneraccess === this.parceiros[2]) {
        // this.img_partner = "parceria-pb";
        this.titlePartner = ", Governo do Estado da Paraíba";
      } else if (this.partneraccess === this.parceiros[3]) {
        // this.img_partner = "parceria-ce";
        this.titlePartner = ", Governo do Município de Juazeiro do Norte";
      } else if (this.partneraccess === this.parceiros[4]) {
        // this.img_partner = "parceria-ce";
        this.titlePartner = ", Governo do Município de Aracati";
      } else if (this.partneraccess === this.parceiros[5]) {
        // this.img_partner = "parceria-ce";
        this.titlePartner = ", Governo do Município de Barbalha";
      } else if (this.partneraccess === this.parceiros[6]) {
        // this.img_partner = "parceria-mg";
        this.titlePartner = ", Governo do Município de Uberaba";
      } else if (this.partneraccess === this.parceiros[7]) {
        // this.img_partner = "parceria-ce";
        this.titlePartner = ", Governo do Município de Jericoacoara";
      } else this.img_partner = "";
    },

    validaLogin(usuario = "", senha = "") {
      if (usuario === "lar.ifce@admin.com" && senha === "laradminifce2020") {
        localStorage.setItem("adminnastro", true);
        this.verify();
        return;
      }

      if (usuario === "gov_ba" && senha === "bahiafiquenolar") {
        // this.img_partner = "parceria-ba";
        this.partner = true;
        this.partneraccess = this.parceiros[0];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[0]);
        localStorage.setItem("partnerLevel", "state");
      } else if (usuario === "gov_ce" && senha === "cearafiquenolar") {
        // this.img_partner = "parceria-ce";
        this.partner = true;
        this.partneraccess = this.parceiros[1];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[1]);
        localStorage.setItem("partnerLevel", "state");
      } else if (usuario === "gov_pb" && senha === "paraiba@fiquenolar") {
        // this.img_partner = "parceria-pb";
        this.partner = true;
        this.partneraccess = this.parceiros[2];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[2]);
        localStorage.setItem("partnerLevel", "state");
      } else if (usuario === "victo" && senha === "vct@unicampfiquenolar") {
        localStorage.setItem("adminnastro", "partner");
        this.partneraccess = localStorage.partneraccess;
        this.partner = true;
        this.getPartner();
      } else if (
        usuario === "gov_juazeirodonorte" &&
        senha === "juazeirodonortefiquenolar"
      ) {
        // this.img_partner = "parceria-ce";
        this.partner = true;
        this.partneraccess = this.parceiros[3];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[3]);
      } else if (usuario === "gov_aracati" && senha === "aracatifiquenolar") {
        // this.img_partner = "parceria-ce";
        this.partner = true;
        this.partneraccess = this.parceiros[4];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[4]);
      } else if (usuario === "gov_barbalha" && senha === "barbalhafiquenolar") {
        // this.img_partner = "parceria-ce";
        this.partner = true;
        this.partneraccess = this.parceiros[5];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[5]);
      } else if (usuario === "gov_uberaba" && senha === "uberabafiquenolar") {
        // this.img_partner = "parceria-mg";
        this.partner = true;
        this.partneraccess = this.parceiros[6];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[6]);
      } else if (usuario === "gov_jericoacoara" && senha === "jericoacoarafiquenolar") {
        // this.img_partner = "parceria-ce";
        this.partner = true;
        this.partneraccess = this.parceiros[7];
        this.loginInvalido = false;
        localStorage.setItem("partneraccess", this.parceiros[7]);
      }
      
      else {
        this.loginInvalido = true;
        this.partner = false;
      }
      if (this.partner) this.getPartner();
    },
    logout() {
      if (localStorage.adminnastro) localStorage.removeItem("adminnastro");
      else {
        localStorage.removeItem("partneraccess");
        localStorage.removeItem("partnerLevel");
      }
      this.partner = false;
      this.$router.push({ name: "Home" });
    }
  }
};
</script>

<style lang="scss">
//.page-item.active .page-link {
//    z-index: 4 !important;
//    color: #fff !important;
//    background-color: #46B766 !important;
//    border-color: #46B766 !important;
//}

//.page-item .page-link {
//    position: relative;
//    display: block;
//    padding: 0.5rem 0.75rem;
//    margin-left: -1px;
//    line-height: 1.25;
//    color: #46B766;
//    background-color: white;
//    border: 1px solid #dee2e6;
//}

.box-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.05);
}
@media (max-width: 1920px) {
  .parceria-ba {
    min-height: 740px;
    background: url("../assets/img/background/bahia_background_1080.svg")
      no-repeat center center;
    background-size: cover;
    position: relative;
  }
}
@media (max-width: 1280px) {
  .parceria-ba {
    // min-height: 720px;
    min-height: 480px;
    background: url("../assets/img/background/bahia_background_768.svg")
      no-repeat center center;
    background-size: cover;
    position: relative;
  }
}

@media (max-width: 1920px) {
  .parceria-ce {
    min-height: 740px;
    background: url("../assets/img/background/ceara_background_1080.svg")
      no-repeat center center;
    background-size: cover;
    position: relative;
  }
}
@media (max-width: 1280px) {
  .parceria-ce {
    // min-height: 720px;
    min-height: 480px;
    background: url("../assets/img/background/ceara_background_768.svg")
      no-repeat center center;
    background-size: cover;
    position: relative;
  }
}
</style>
