import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import Theme from "@/plugins/theme";
import VueTheMask from "vue-the-mask";
import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
  setInteractionMode
} from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import pt_BR from "vee-validate/dist/locale/pt_BR";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Notifications from "vue-notification";
import Unicon from "vue-unicons";
import {
  uniWhatsapp,
  uniPhone,
  uniShop,
  uniInstagram,
  uniFacebook,
  uniFacebookF,
  uniGlobe,
  uniMap,
  uniTwitter
} from "vue-unicons/src/icons";
import money from "v-money";
import vSelect from "vue-select";
import vueDebounce from "vue-debounce";
import infiniteScroll from "vue-infinite-scroll";
import VuePageTransition from "vue-page-transition";
import VueFormWizard from "vue-form-wizard";
import ShareNetwork from "vue-social-sharing";

import "leaflet/dist/leaflet.css";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import i18n from "./i18n";

Unicon.add([
  uniWhatsapp,
  uniPhone,
  uniShop,
  uniInstagram,
  uniFacebook,
  uniFacebookF,
  uniGlobe,
  uniMap,
  uniTwitter
]);

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend("cnpj", {
  validate: value => value.length === 18,
  message: "CNPJ incompleto"
});

setInteractionMode("eager");
localize("pt_BR", pt_BR);

Vue.use(Unicon);
Vue.use(Theme);
Vue.use(VueTheMask);
Vue.use(vueDebounce);
Vue.use(infiniteScroll);
// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Notifications);
Vue.use(money, {
  decimal: ",",
  thousands: ".",
  prefix: "R$ ",
  precision: 2,
  masked: false
});
Vue.use(VuePageTransition);
Vue.use(VueFormWizard);
Vue.use(ShareNetwork);

Vue.component("v-select", vSelect);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
