<template>
  <div>
    <b-navbar
      toggleable="lg"
      fixed="top"
      :print="true"
      type="dark"
      variant="success"
    >
      <div
        :class="{
          container:
            $route.name === 'Servicos' || $route.name === 'Mapa' ? false : true,
          'container-fluid':
            $route.name === 'Servicos' || $route.name === 'Mapa' ? true : false
        }"
      >
        <router-link to="/">
          <b-navbar-brand>
            <img
              src="@/assets/img/logoLar.png"
              alt="Logo FiqueNoLAR"
              width="100px"
              height="40px"
            />
          </b-navbar-brand>
          <b-navbar-brand>
            <img
              src="@/assets/img/if_white.svg"
              alt="Logo IFCE"
              width="30px"
              height="30px"
            />
          </b-navbar-brand>
          <b-navbar-brand
            class="parceria-img-nav"
            v-if="estadoParceiro != undefined"
          >
            <span class="mr-2 parceria-text-span">Em parceria</span>
            <!-- <span class="mr-2 parceria-gov-text-span">{{ img.text }}</span> -->
            <img
              :src="require(`../assets/img/apoio/${img.logo}`)"
              :alt="img.alt"
              :height="img.height"
            />
          </b-navbar-brand>
        </router-link>

        <!-- <b-navbar-toggle
        v-if="$route.name === 'Home'"
        target="nav-collapse"
      ></b-navbar-toggle> -->

        <button
          v-if="$route.name === 'Servicos'"
          type="button"
          aria-label="Toggle navigation"
          aria-controls="nav-collapse"
          aria-expanded="false"
          class="navbar-toggler"
          @click="$sidebar.displaySidebar(!$sidebar.showSidebar)"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <!-- <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto dropdown-personalizado"> -->
          <div class="dropdown-personalizado" v-show="$route.name !== 'Servicos' ? true : false">
            <b-dropdown right variant="success" size="sm" :no-caret="true">
              <template v-slot:button-content>
                <country-flag
                  :country="lang.flag"
                  v-if="lang.flag !== undefined"
                />
              </template>
              <b-dropdown-item
                v-for="local in locales"
                :key="local.flag"
                @click="lang = local"
              >
                <country-flag :country="local.flag" />
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- </b-navbar-nav>
        </b-collapse> -->
      </div>
    </b-navbar>
  </div>
</template>
<script>
import CountryFlag from "vue-country-flag";
export default {
  components: {
    CountryFlag
  },
  data() {
    return {
      lang: {},
      storageLang: {},
      locales: [
        { value: "pt-BR", flag: "br" },
        { value: "en-US", flag: "gb" },
        { value: "fr-FR", flag: "fr" }
        // { value: "es-ES", flag: "es" }
      ],
      img: {
        logo: "",
        alt: "img",
        text: "",
        height: "30px"
      },
      estadoParceiro: undefined
    };
  },
  async created() {
    if (
      localStorage.ultimoEstadoSelecionado &&
      localStorage.ultimoEstadoSelecionado != null
    ) {
      this.estadoParceiro = JSON.parse(localStorage.ultimoEstadoSelecionado);
      if (this.estadoParceiro.uf === "CE") {
        this.img.logo = "gov_ce.svg";
        this.img.alt = "Logo Ceará";
        this.img.height = "40px";
        this.img.text = "Gov. CE";
      } else if (this.estadoParceiro.uf === "BA") {
        this.img.logo = "gov_ba_brasao_hor.png";
        this.img.alt = "Logo Bahia";
        this.img.height = "30px";
        this.img.text = "Gov. BA";
      } else this.estadoParceiro = undefined;
    }
    this.lang = await this.getLanguage();
    Object.assign(this.storageLang, this.lang);
    // await console.log(this.lang);
  },
  watch: {
    lang(lang) {
      this.setLanguage(lang);
      this.$i18n.locale = lang.value;
      if (lang.value != this.storageLang.value) this.$router.push("/redirect");
    }
  },
  methods: {
    composicao(...funcoes) {
      return function(valor) {
        return funcoes.reduce((acc, fn) => {
          return fn(acc);
        }, valor);
      };
    },
    getLanguageLocalStorage() {
      return localStorage.getItem("language");
    },
    convertToObject(str) {
      return str === null ? { value: "pt-BR", flag: "br" } : JSON.parse(str);
    },
    verifyIncludeVetorLanguage(obj) {
      if (
        this.locales.find(
          ({ value, flag }) => value === obj.value && flag === obj.flag
        ) === undefined
      )
        return { value: "pt-BR", flag: "br" };
      return obj;
    },
    convertToString(obj) {
      return JSON.stringify(obj);
    },
    setLanguageLocalStorage(str) {
      localStorage.setItem("language", str);
    },
    getLanguage() {
      const funcao = this.composicao(
        this.getLanguageLocalStorage,
        this.convertToObject,
        this.verifyIncludeVetorLanguage
      );
      return funcao();
    },
    setLanguage(obj) {
      const funcao = this.composicao(
        this.convertToString,
        this.setLanguageLocalStorage
      );
      return funcao(obj);
    }
  }
};
</script>
<style lang="scss" scoped>
@media (max-width: 321px) {
  .parceria-img-nav {
    display: none;
  }
}
@media (max-width: 437px) {
  .parceria-text-span {
    display: none;
  }
}
@media (max-width: 520px) {
  .parceria-gov-text-span {
    display: none;
  }
}
</style>
