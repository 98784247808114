<template>
  <div
    class="button-to-up"
    @click="toUp"
    :class="{ none: lastScrollPosition < OFFSET }"
  >
    <b-icon-chevron-up />
  </div>
</template>

<script>
export default {
  data() {
    return {
      lastScrollPosition: 0,
      OFFSET: 200,
      sac_open: false
    };
  },
  mounted() {
    this.lastScrollPosition = window.pageYOffset;
    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      if (window.pageYOffset < 0) return;
      if (Math.abs(window.pageYOffset - this.lastScrollPosition) < this.OFFSET)
        return;
      this.lastScrollPosition = window.pageYOffset;
    },
    toUp() {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }
};
</script>

<style lang="scss" scoped>
.none {
  transform: scale(0) !important;
}
</style>
