<template>
  <fnl-transition>
    <div class="my__list__item">
      <div class="my__list__item__main">
        <div
          class="my__list__item__main__image"
          :class="{ 'cursor-pointer': callbackExists }"
          @click="noClick"
        >
          <slot name="imagem"></slot>
        </div>
        <div
          class="my__list__item__main__info"
          :class="{ 'cursor-pointer': callbackExists }"
          @click="noClick"
        >
          <div class="my__list__item__main__info__primary">
            <slot name="info_primary"></slot>
          </div>
          <div class="my__list__item__main__info__secondary">
            <slot name="info_secondary"></slot>
          </div>
        </div>
        <div class="my__list__item__main__moreinfo">
          <slot name="more_actions"></slot>
          <div
            class="circle-button"
            :class="{ 'circle-button--open': show }"
            @click="show = !show"
          >
            <i class="ti-angle-down"></i>
          </div>
        </div>
      </div>
      <b-collapse v-model="show">
        <div class="my__list__item__overplus p-4">
          <slot name="overplus"></slot>
        </div>
      </b-collapse>
    </div>
  </fnl-transition>
</template>

<script>
import FnlTransition from "./FnlTransition";
export default {
  components: {
    FnlTransition
  },
  props: {
    callback: {
      type: Function
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    callbackExists() {
      return this.callback ? true : false;
    }
  },
  methods: {
    noClick() {
      if (this.callbackExists) {
        this.callback();
      }
    }
  }
};
</script>
