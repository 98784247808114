<template>
  <div id="app">
    <notifications group="toast" />
    <vue-page-transition name="overlay-up" style="height: 100%; width: 100%;">
      <router-view />
    </vue-page-transition>
    <button-to-up />
  </div>
</template>

<script>
import Service from "./services/ApiServices";
import { v4 as uuidv4 } from "uuid";
import ButtonToUp from "./components/ButtonToUp";

export default {
  components: {
    ButtonToUp
  },
  mounted() {
    const request = new Service();
    const user_id = localStorage.getItem("user_id");
    const uuid = user_id ? user_id : uuidv4();
    localStorage.setItem("user_id", uuid);
    request.create({ uuid }, "usuarios");
  }
};
</script>
