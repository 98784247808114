<template>
  <footer class="footer bg-light">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 h-100 text-center text-lg-left my-auto">
          <ul class="list-inline mb-2">
            <li class="list-inline-item">
              <router-link to="/sobre">
                <a href="#">{{ $t("footer.sobre") }}</a>
              </router-link>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <router-link to="/equipe">
                <a href="#">{{ $t("footer.equipe") }}</a>
              </router-link>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <router-link to="/termos">
                <a href="#">{{ $t("footer.termos") }}</a>
              </router-link>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <router-link to="/noticias">
                <a href="#">{{ $t("footer.noticias") }}</a>
              </router-link>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <router-link to="/dicas">
                <a href="#">{{ $t("footer.dicas") }}</a>
              </router-link>
            </li>
            <li class="list-inline-item">&sdot;</li>
            <li class="list-inline-item">
              <router-link to="/areaparceiro">
                <a href="#">{{ $t("footer.area-parceiro") }}</a>
              </router-link>
            </li>
          </ul>
          <p class="text-muted small mb-4 mb-lg-0 footer_p">
            &copy; {{ $t("footer.developed-by") }}
          </p>
        </div>
        <div class="col-lg-6 h-100 text-center text-lg-right my-auto">
          <a
            href="https://www.sbc.org.br/8-eventos/2211-fique-no-lar-fiquenolar"
            target="_blank"
          >
            <img
              src="../assets/img/apoio/chancela_sbc.svg"
              alt=""
              width="200px"
              height="112px"
            />
          </a>
          <!-- <ul class="list-inline mb-0">
            <li class="list-inline-item mr-3">
              <b-icon-controller
                class="card-list-category-item-icon"
                style="color:#007bff"
              />
            </li>
            <li class="list-inline-item mr-3">
              <b-icon-lightning-fill
                class="card-list-category-item-icon"
                style="color:#007bff"
              />
            </li>
            <li class="list-inline-item">
              <b-icon-phone
                class="card-list-category-item-icon"
                style="color:#007bff"
              />
            </li>
          </ul>
          <span
            class="badge badge-primary acessos mr-1 mb-2"
            title="Quantidade de Acessos"
            >Acessos: {{ totalAcessos }}</span
          > -->
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Service from "../services/ApiServices";

export default {
  data() {
    return {
      totalAcessos: 0
    };
  },

  mounted() {
    if (localStorage.totalAcessos)
      this.totalAcessos = localStorage.totalAcessos;
  },

  created() {
    // this.getAcessos();
  },

  methods: {
    async getAcessos() {
      const request = new Service();
      let requestResult = await request.totalAcessos(
        {},
        "usuarios/totalAcessos"
      );
      this.totalAcessos = requestResult
        ? requestResult.totalAcessos
        : undefined;
      localStorage.setItem("totalAcessos", this.totalAcessos);
    }
  }
};
</script>
<style scoped>
footer.footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.footer_p {
  text-align: left;
}
</style>
